export const locale = {
  withTemplate: "Use Template",
  withoutTemplate: "Proceed without a template",
  saveTemplate: "Save Template",
  deleteTemplate: "Delete Template",
  addTemplate: "Add Template",
  useTemplate: "Use Template",
  emptyTemplate: "No Template Available",
  emptyTemplateMessage: "You don't have fuel code templates yet.",
  fuelCodeTemplates: "Fuel Code Templates",
  saveCodeTemplate: "Save Fuel Code As Template",
  maxUsage: "Maximum Usage",
  durationDays: "Duration",
  durationInDays: "Duration In Days",
  minMaxDuration: "Duration range must be {0}.",
  minDuration: "Duration must be greater than 0",
  viewRedemptions: "View redemptions",
  save: "Save",
  editDetails: "Edit details",
  deactivate: "Deactivate",
  reactivate: "Reactivate",
  activate: "Activate",
  manageFleetCards: "Manage fleet cards",
  requestFleetCard: "Request Fleet Card",
  manageFleetVehicles: "Manage fleet vehicles",
  manageYourVehicle: "Manage your vehicles and keep their information up to date.",
  addVehicles: "Add Vehicles",
  addVehicle: "Add Vehicle",
  add: "Add",
  sorryNoResultFound: "Sorry, no results found",
  weCouldNotFindAMatchForKey: "We could not find a match for “{0}“.",
  pleaseTryADifferentOne: "Please try a different one.",
  vehicleSuccessfullyRegistered: "Vehicle successfully registered!",
  vehicleInformationSuccessfullyEdited: "Vehicle information successfully edited!",
  youHaveSuccessfullyRegisteredPlateNumber:
    "You have successfully registered {0} to the vehicles’ list.",
  youHaveEditedVehicleDetails: "You have successfully changed the information of {0}.",
  registerNewVehicle: "Register New Vehicle",
  vehicleIdAlreadyExists: "This Vehicle ID already exists.",
  cancel: "Cancel",
  manageFleetDrivers: "Manage fleet drivers",
  manageYourDrivers: "Manage your drivers and keep their information up to date.",
  addDriver: "Add Driver",
  driverSuccessRegistered: "Driver successfully registered!",
  driverSuccessEdited: "Driver information successfully edited!",
  successfullyRegisteredDriverName:
    "You have successfully registered {0} {1} to the drivers' list.",
  successfullyChangeInfoOfDriverName: "You have successfully changed the information of {0} {1}",
  registerNewDriver: "Register New Driver",
  driverLicenseIdExists: "This Driver ID already exists.",
  enterFirstNameHere: "Enter first name here",
  enterLastNameHere: "Enter last name here",
  pleaseEnterAValidMobileNumber: "Please enter a valid mobile number.",
  plateNumber: "Plate Number",
  vehicleModelType: "Vehicle Model Type",
  remarksOptions: "Remarks (optional)",
  remarks: "Remarks",
  enterRemarksHere: "Enter remarks here",
  plateNumberPlaceholder: "XXXXXX (don't include spaces or dash)",
  fleetManageCardActiveStatus:
    "You are about to {0} fleet card no. {1}. This will enable the driver to do transactions. Do you want to proceed?",
  fleetCardActiveStatus:
    "You are about to {0} fleet card no. {1}. This will enable the driver to do transactions. Do you want to proceed?",
  fleetCardDeactivateStatus:
    "You are about to {0} fleet card no. {1}. This will prevent the driver to use the card {2}. Do you want to proceed?",
  askDeactivateFleetCard: "Deactivate Fleet Card",
  askTerminateFleetCard: "Terminate Fleet Card",
  askActivateFleetCard: "Activate Fleet Card",
  youAreAboutToDeactivateVehicle:
    "You are about to deactivate vehicle {0}. This will temporarily prevent vehicle {1} from doing any transaction. Do you want to proceed?",
  youAreAboutToReactivateVehicle:
    "You are about to reactivate vehicle {0}. This will enable vehicle {1} to do transactions again. Do you want to proceed?",
  askDeactivateVehicle: "Deactivate vehicle?",
  askReactivateVehicle: "Reactivate vehicle?",
  yesDeactivateIt: "Yes, Deactivate it",
  yesReactivateIt: "Yes, Reactivate it",
  yesTerminateIt: "Yes, Terminate it",
  yesActivateIt: "Yes, Activate it",
  youAreAboutDeactivateDriver:
    "You are about to deactivate {0}’s account. This will temporarily prevent {1} from doing any transaction. Do you want to proceed?",
  youAreAboutReactivateDriver:
    "You are about to reactivate {0}’s account. This will enable {1} to do transactions again. Do you want to proceed?",
  askDeactivateDriver: "Deactivate driver?",
  askReactivateDriver: "Reactivate driver?",
  firstName: "First Name",
  lastName: "Last Name",
  pleaseEnterAValidLicensedId: "Please enter a valid driver license ID.",
  mobileNumber: "Mobile Number",
  driverId: "Driver ID",
  all: "All",
  nameMobileNumberDriverId: "Name, Mobile Number, Driver ID",
  name: "Name",
  status: "Status",
  model: "Model",
  emailAddress: "Email Address",
  password: "Password",
  rememberMe: "Remember Me",
  logIn: "Log In",
  theEnteredEmailPassIncorrect: "The entered email or password is incorrect",
  fleetPortal: "Fleet Portal",
  pleaseEnterAValidEmailAddress: "Please enter a valid email address.",
  cashInHistory: "Cash In History",
  cashInHistoryCapitalize: "Cash In History",
  referenceId: "Reference ID",
  cashInId: "Cash In ID",
  buyFuelId: "Buy Fuel ID",
  redemptionId: "Redemption ID",
  referenceNumber: "Reference Number",
  referenceIdAndNumber: "Reference ID, Reference Number",
  cashInIdAndNumber: "Cash In ID, Reference Number",
  amount: "Amount",
  createdDate: "Created Date",
  fullfilledDate: "Fulfilled Date",
  cashInChannel: "Cash-In Channel",
  active: "Active",
  inactive: "Inactive",
  terminated: "Terminated",
  completed: "Completed",
  pending: "Pending",
  failed: "Failed",
  expired: "Expired",
  gasName: "Gas {0}",
  diesel: "Diesel",
  gas: "Gas",
  gasoline: "Gasoline",
  manualPaymaya: "Manual - Paymaya",
  manualBankDeposit: "Manual Bank Deposit",
  purchasedDate: "Purchased Date",
  product: "Product",
  creditPrice: "Credit Price (₱/L)",
  volume: "Volume",
  totalCreditAmount: "Total Credit Amount",
  buyFuelHistory: "Buy fuel history",
  buyFuel: "Buy fuel",
  buyFuelCapitalize: "Buy Fuel",
  pleaseSelectProductYouWish: "Please select product you wish to purchase.",
  cashFlow: "Cash Flow",
  cashIn: "Cash In",
  fuel: "Fuel",
  generalFuelCodes: "Generate Fuel Codes",
  myFuelCodes: "My Fuel Codes",
  fleetActivity: "My Fleet Activity",
  fleetCard: "Fleet Card",
  fleetCardManagement: "My Fleet Card",
  redemptions: "Redemptions",
  drivers: "Drivers",
  vehicles: "Vehicles",
  onlyAlphaNumericAllowed: "Only Alphanumeric characters are allowed.",
  onlyAlphaNumericAllowedAndDash:
    "Only Alphanumeric characters and the dash symbol ( - ) are allowed.",
  onlyAlphaNumericAllowedAndSpecial:
    "Only Alphanumeric characters and the following special symbol ( - , . , spaces ) are allowed.",
  mobileNumberIsInvalid: "This mobile number is invalid.",
  driverLicenseIdIsInvalid: "This driver license ID is invalid.",
  date: "Date",
  to: "to",
  gotIt: "Got It",
  onlyNumericAreAllowed: "Only numeric characters are allowed.",
  ohSnap: "Oh snap!",
  somethingWentWrongPleaseTryAgain: "Something went wrong. Please try again.",
  tryAgain: "Try Again",
  generateFuelCode: "Generate fuel code",
  balance: "Balance",
  productType: "Product Type",
  volumeRedeemLimit: "Volume Redeem Limit",
  eligiblePeriod: "Eligible Period",
  eligibleDrivers: "Eligible Drivers",
  eligibleVehicles: "Eligible Vehicles",
  continue: "Continue",
  searchOrSelect: "Search or select",
  kindlyReviewDetails: "Kindly review the below details before generating the fuel code.",
  generateFuelCodeCapitalize: "Generate Fuel Code",
  successfullyAddedTemplate: "You Successfully Added A Template!",
  successfullyRemovedTemplate: "You Successfully Removed A Template!",
  successfullyGeneratedFuelCode: "You Successfully Generated Fuel Code!",
  youMayStartUsingThisCode: "You may start using this code to redeem fuels at the stations.",
  goToMyFuelCodes: "Go to My Fuel Codes",
  generateAnotherCode: "Generate Another Code",
  maximumAllowedRedeemLiters: "Maximum allowed redeem limit is {0} liters.",
  minimumAllowedLitre: "Minimum allowed redeem limit is 1 liter.",
  priceHistory: "Price History",
  effectiveDate: "Effective Date",
  fuelPrice: "Fuel Price",
  basePrice: "Base Price",
  priceMovement: "Price Movement",
  discount: "Discount",
  creditPriceHistory: "Credit Price",
  contractPrice: "Contract Price",
  myFuelCodesTitle: "My fuel codes",
  createFuelCodes: "Create fuel codes and keep track of their expiry dates.",
  dateGenerated: "Date Generated",
  fuelCode: "Fuel Code",
  codeStartDate: "Code Start Date",
  codeEndDate: "Code End Date",
  volumeLimit: "Volume Limit",
  volumeRedeemed: "Volume Redeemed",
  useLimit: "Use Limit",
  useCount: "Use Count",
  eligibleDriversAndVehicles: "Eligible Drivers and vehicles",
  scheduled: "Scheduled",
  deactivated: "Deactivated",
  actions: "Actions",
  stepOneSelectFuel: "Step 1: Select Fuel",
  viewPriceHistory: "View Price History",
  liters: "Liters",
  liter: "Liter",
  peso: "Peso",
  stepTwoEnterAmount: "Step 2: Enter Amount",
  buyFuelHistoryCapitalize: "Buy Fuel History",
  totalLitersIsAmount: "Total Liters: {0}",
  totalAmountIs: "Total Amount: {0}",
  minimumOrderIs1Liter: "Minimum order is 1 liter.",
  askConfirmWithPayment: "Proceed with payment?",
  nLiter: "{0} Liter",
  nLiters: "{0} Liters",
  ofText: "of {0}",
  isAboutToBePurchased: "is about to be purchased.",
  kindlyReviewTheDetails: "Kindly review the details before proceeding.",
  productPrice: "Product Price",
  total: "Total",
  totalCaps: "TOTAL",
  pay: "Pay",
  success: "Success",
  paymentConfirmed: "Your payment has been confirmed.",
  goToBuyFuelHistory: "Go to Buy Fuel History",
  askDeactivateFuelCode: "Deactivate Fuel Code?",
  youAreAboutDeactivateFuelCode:
    "You are about to deactivate fuel code {0}. This will disable the said fuel code forever and will not be usable anymore. Do you want to proceed?",
  buyFuelFailed: "Buy Fuel transaction failed",
  errorProcessingPayment: "There was an error processing your payment. Please try again.",
  buyFuelAgain: "Buy Fuel Again",
  oops: "Oops! Something went wrong",
  pleaseRefreshYourBrowser: "Please refresh your browser",
  pleaseReviewBefore: "Are you sure you want to save all changes made to this",
  backToHomePage: "Back to Home Page",
  okayGotIt: "Okay, Got it!",
  viewTrackRedemptions: "View, track redemption activities of your fleet.",
  redemptionDate: "Redemption Date",
  redemptionStation: "Redemption Station",
  stationBusinessNames: "Station Business Name",
  aveCreditPrice: "Ave. Credit Price",
  driverIdPlateNumberFuelCode: "Driver ID, Plate No., Fuel Code",
  driverIdPlateNumberFuelCodeOrNumberBusinessName:
    "Driver ID, Plate No., Fuel Code, OR Number, Station Business Name",

  literVolume: "Liter Volume",
  welcome: "Welcome,",
  yourFleetConsumptionForTheMonth: "Your Fleet Consumption for the Month",
  activateAccount: "Activate Account",
  basicInformation: "Basic Information",
  confirmPassword: "Confirm Password",
  setPassword: "Set Password",
  use8CharWithMix:
    "Use 8 or more characters with a mix of uppercase and lowercase letters and numbers/symbols",
  passwordsDoNotMatch: "Passwords do not match",
  passwordDoesNotMeetRequirements: "Password does not meet requirements",
  goToLogin: "Go to Login",
  yourAccountHasBeenActivated: "Your account has been successfully activated.",
  youCanNowLoginIn: "You can now use your password to log in to your account.",
  expiredActivationLinkToken:
    "The activation link has expired. Please contact your administrator to request for a new activation link.",
  invalidActivationLinkToken:
    "This activation link is invalid or has been used. Please try logging in using your fleet email and nominated password. If you did not activate this account, please contact us immediately.",
  pleaseSelectProductType: "Please select a Product type",
  totalBalance: "Total Current Balance: {0}",
  youHaveInsufficientBalance: "You have insufficient balance.",
  notAuthorized: "Not Authorized",
  sorryYouAreNotAuhorized: "Sorry, but you are not authorized to access this page.",
  proceedCashInRequestForm: "Proceed to Cash In Request Form",
  sendSMSToEligibleDrivers: "Send Fuel code as SMS to eligible drivers",
  station: "Station",
  stationList: "Station List",
  priceLocqForBusinessStations: "PriceLOCQ for Business Stations",
  viewListOfStations: "View list of stations.",
  filters: "Filters",
  stationName: "Station Name",
  availableProducts: "Available Products",
  city: "City",
  province: "Province",
  clearAll: "Clear All",
  applyFilter: "Apply Filters",
  fuelCodeType: "Fuel Code Type",
  assignedDriver: "Assigned Driver",
  assignedDrivers: "Assigned Drivers",
  assignedVehicle: "Assigned Vehicle",
  sendSms: "Send SMS",
  action: "Action",
  startDate: "Start Date",
  endDate: "End Date",
  youMayCreateMultipleFuelCodes: "You may create multiple fuel codes here.",
  clickAddFuelToStart: "Click Add Fuel Code to start.",
  addFuelCode: "Add Fuel Code",
  driverSpecific: "Driver Specific",
  vehicleSpecific: "Vehicle Specific",
  driverVehicleSpecific: "Driver and Vehicle Specific",
  anyDriverVehicle: "Any Driver, Any Vehicle",
  sendFuelCodeAsSms: "Send Fuel Code as SMS to assigned driver",
  autoRenewFuelCode: "Auto-renew Fuel Code every {0}",
  yes: "Yes",
  no: "No",
  edit: "Edit",
  duplicate: "Duplicate",
  delete: "Delete",
  editFuelCodeTemplate: "Edit Fuel Code Template",
  editFuelCode: "Edit Fuel Code",
  youAreAboutToGenerateFuelCode: "You are about to generate {0} and send to {1}",
  youAreAboutToGenerate: "You are about to generate {0}.",
  youAreAboutToOverrideThisFuelCode: "You are about to override this fuel code.",
  andSendTo: " and send to {1}",
  forAnyDriver: " for any driver{0}",
  youAreAboutToGenerateFuelCodeFromTemplate: "You are about to generate {0}",
  youAreAboutToAddTemplate: "You are about to save this template.",
  youAreAboutToDeleteTemplate: "Are you sure you want to delete this fuel code template?",
  youAreAboutToUpdateTemplate: "You are about to update this template.",
  generalFuelCodesCapitalized: "Generate Fuel Code",
  driver: "driver",
  youMayStartUsingCodes: "You may start using the code(s) to redeem fuels at the stations.",
  youMayStartUsingTemplate: "You may start using your new template when adding a fuel code.",
  fuelCodeLowerCase: "fuel code",
  fuelCodesLowerCase: "fuel codes",
  applyFilters: "Apply Filters",
  totalFuelCodes: "Total Fuel Codes",
  activeFuelCodes: "Active Fuel Codes",
  none: "None",
  areYouSure: "Are you sure?",
  areYouSureYouWantToLeave: "Are you sure you want to leave this page",
  leaveAnyway: "Leave Anyway",
  stay: "Stay",
  youWillLoseAllFuelCodes: "You will lose all fuel codes created on this page.",
  creditOrDebitCard: "Credit or Debit Card",
  overTheCounter: "Over-The-Counter Bank",
  onlineBanking: "Online Banking",
  selectCashInChannel: "Select Cash-In Channel",
  weAreCurrentlyAccepting: "We are currently accepting Visa, Mastercard, and JCB.",
  stepOneSelectCard: "Step 1: Select Card to Use",
  stepOneSelectBank: "Step 1: Select an Online Bank",
  nameOnCard: "Name on Card",
  cardNumber: "Card Number",
  expiryDate: "Expiry Date",
  cvv: "CVV",
  enterNameHere: "Enter name here",
  enterCardNumber: "Enter card number",
  enterEmailHere: "Enter email here",
  invalidEmailFormat: "Invalid email address format.",
  mmYY: "MM/YY",
  cardNumberYouEnterIsIncorrect:
    "The card number you entered is in the incorrect format. Please check your card and try again.",
  weAreOnlyAcceotingTheseCards: "We are only currently accepting VISA, Mastercard, and JCB.",
  theExpiryDateYouEnterIsIncorrect:
    "The expiry date you entered is in the incorrect format. Please check your card and try again.",
  currentBalance: "Current balance",
  minimumCashIn: "Minimum cash in",
  fee: "Fee",
  minimumCashInIsAmount: "Minimum cash-in amount is {0}",
  maximumCashInIsAmount: "Maximum cash-in amount is ₱{0}",
  yourPaymentHasBeenConfirmed: "Your payment has been confirmed.",
  successTitle: "Success!",
  goToCashInHistory: "Go to Cash In History",
  newCashIn: "New Cash In",
  paymentMethod: "Payment Method",
  cashInTransactionFailed: "Cash in transaction failed!",
  thereWasAnErrorProcessingPayment: "There was an error processing your payment. Please try again.",
  backToCashIn: "Back to Cash In",
  selectProductType: "Select Product Type",
  export: "Export",
  card: "Card",
  cashInTransactionCancel: "Cash in transaction canceled",
  thisTransactionWasCanceled:
    "This transaction was canceled and will not be charged to your account.",
  bdo: "Banco De Oro Unibank",
  unionBankOfThePhil: "UnionBank of the Philippines",
  bpi: "BPI/BPI Family Savings Bank",
  rcbc: "Rizal Commercial Banking corporation",
  metroBank: "Metrobank",
  pnb: "Philippine National Bank",
  bankTransactionFee: "Transaction Fee: {0}",
  otherServices: "Other Services",
  loans: "Loans",
  vehicleFinancing: "Vehicle Financing",
  email: "Email Address",
  contactNumber: "Contact Number",
  loanProduct: "Loan Product",
  loanInquirySent: "Loan Inquiry Has Been Sent!",
  loanInquiryNotSent: "Loan Inquiry Has Not Sent!",
  loanInquirySentMessage:
    "Our Lending Partner will reach out to you within 24 hours. You may also check your email for a summary of your inquiry details.​",
  loanInquiryNotSentMessage: "Kindly try again later.",
  chooseRegion: "Choose Region",
  chooseBranch: "Choose Branch",
  selectProduct: "Select Product",
  selectRegion: "Select Region",
  selectBranch: "Select Branch",
  dominionPrivacyPolicy: "I have read and AGREE TO Global Dominion Financing, Inc’s ",
  locqPrivacyPolicy: "I have read and AGREE TO PriceLOCQ’s ",
  forPendingInquiries:
    "For pending inquiries and other concerns, you may directly contact Global Dominion at ",
  findingTheRightLoan: "Find the right loan for your business’s needs. ",
  submitAndContactMe: "Submit and Contact Me",
  inquiryId: "Inquiry ID",
  mobilePlaceholder: "09XXXXXXXXX",
  deactivatedFleetAccount: "Deactivated Fleet Account!",
  reload: "Reload",
  approxPesoEquivalent: "Approx. Peso Equivalent: {0}",
  limitCodeOneTime: "Limit code to one-time use",
  oneTimeUse: "One-time use",
  sentAsSms: "Sent as SMS?",
  mastercard: "Mastercard",
  visa: "Visa",
  jcb: "JCB",
  saveMyCardDetails: "Save my card details",
  removeThisCard: "Remove this card?",
  ifYouRemoveThisCard:
    "If you remove this card, it will no longer appear in your saved cards options.",
  remove: "Remove",
  locqRebate: "LOCQ Rebate",
  chooseFromMySavedCard: "Choose from my saved cards",
  useATemporaryCard: "Use a temporary card",
  addNewCard: "Add New Card",
  cardSuccessfullyAdded: "Card successfully added!",
  cardSuccessfullyDeleted: "Card successfully deleted!",
  youHaveSuccesfullyDeletedFromYourSavedCardsOption:
    "You have successfully deleted {0} from your saved cards option.",
  youMayNowContinueYourCashInTransaction: "You may now continue your cash in transaction.",
  noCardsAreCurrentlySaved: "No cards are currently saved.",
  addingOfCardFailed: "Adding of card failed",
  weEncounteredAnErrorWhileSavingYourCardDetails:
    "We encountered an error while saving your card details. Please try again.",
  addingCardWillRedirect:
    "Adding a card will redirect you to an external page for validation of details. Don’t worry, any charged amount will be refunded!",
  dashboard: "Dashboard",
  dispensedToPlateNumber: "Dispensed to/ Plate Number",
  dispensedTo: "Dispensed to",
  availableProduct: "Available Products",
  orNumber: "OR Number",
  vehicle: "Vehicle",
  drumContainer: "Drums/Container",
  fuelCodeRedemptionDate: "Fuel Code/ Redemption Date",
  discountUpdate: "Discount Update",
  accountSpecificPriceMovement: "Account-specific Price Movement",
  nationalPriceMovement: "National Price Movement",
  priceMovementType: "Price Movement Type",
  weCouldNotFindAMatchForSearchCriteria: "We could not find a match for your search criteria",
  initialPriceUponOnboarding: "Initial Price Upon Onboarding",
  latestPriceMovement: "Latest Price Movement",
  yourLifetimeSavings: "Your Lifetime Savings",
  learnHowYouCanSaveMore: "Learn how you can save more {0}.",
  here: "here",
  priceMovementHistory: "Price Movement History",
  last4Weeks: "Last 4 weeks",
  last6Months: "Last 6 months",
  last12Months: "Last 12 months",
  dateRange: "Date Range",
  priceMovements: "Price Movements",
  priceMovementsCumulative: "Price Movements (Cumulative)",
  contractPrices: "Contract Prices",
  addFuelCodeSubTitle:
    "The generated code can only be redeemed at these {0}. Kindly inform your drivers in advance to avoid issues upon redemption.",
  stations: "stations",
  operatingHours: "Operating hours",
  open: "Open",
  closed: "Closed",
  getDirection: "Get Direction",
  distanceAway: "{0} km away from you",
  mapView: "Map View",
  listView: "List View",
  numberOfStations: "{0} Stations",
  backToPricelocqStations: "Back to PriceLOCQ Stations",
  acceptedCustomer: "Accepted Customers",
  upcomingPriceMovement: "Upcoming price movement {0}: {1} {2}",
  priceLocqDOesNotHaveAccessLocation: "PriceLOCQ does not have permission to show you location",
  redeemFromAnyStation: "Redeem from any PriceLOCQ for Business station.",
  redeemFromSelectedStation:
    "{0} Your account can only redeem from the stations displayed in this list.",
  limitedStations: "Limited stations",
  loading: "Loading...",
  theListIsUpdatedFrequentlyForPricelocqStation:
    "The list is updated frequently for new PriceLOCQ stations so stay tuned!",
  promos: "Promos",
  loadMore: "Load more",
  viewDetails: "View Details",
  noPromoAvailable: "No promo available",
  youDoNotHaveAnyPromotionsYet: "You do not have any promotions yet.",
  joinNow: "Join now",
  driverFuelCodeError:
    "Driver is currently deactivated. Please select other driver for the mean time.",
  vehicleFuelCodeError:
    "Vehicle is currently deactivated. Please select other vehicle for the mean time.",
  voided: "Voided",
  anyDriverAnyVehicle: "Any Driver, Any Vehicle",
  any: "Any",
  overrideDetails: "Override Details",
  overrideFuelCode: "Override Fuel Code",
  proceed: "Proceed",
  fuelcodeUpdated: "Fuel code updated!",
  overrideFuelCodeDetails: "Override Fuel Code Details",
  currentRedeemedVolumeIsN: "Current redeemed volume is {0}",
  codeHasBeenUsed: "Code has beed used {0}",
  times: "times",
  time: "time",
  overrideSuccessful: "Override successful!",
  youHaveSuccessfullyOverridenFuelCode:
    "You have successfully overriden the information the information of {0}.",
  dueToCurrentMarket:
    "Due to current market conditions, we have implemented limits on your fuel inventory. You can only buy {0} based on your fuel inventory limit. For any concerns, please email business@locq.com.",
  editTemplate: "Edit Template",
  voidTransaction: "Void Transaction",
  voidingThisTransaction:
    "Voiding this transaction will return {0} to the account's fuel code and fleet balance. Input the reason below to proceed.",
  transactionVoided: "Transaction successfully voided",
  thisTransactionHasBeenVoided:
    "This transaction has been voided and {0} has been successfully returned to {1}",
  void: "Void",
  editRemarks: "Edit Remarks",
  reason: "Reason",
  yourTotalFuelLimit: "Your Total Fuel Inventory Limit is {0}",
  fuelInventoryLimit: "Fuel inventory limit: {0}",
  currentBalanceLimit: "Current liter balance: {0}",
  redeemed: "Redeemed",
  autoRenew: "Auto-renew",
  allDay: "24/7",
  forgotPassword: "Forgot password?",
  passwordReset: "Password Reset",
  pleaseEnterYourEmailAddress: "Please enter your email address",
  pleaseEnterNewPassword: "Please enter a new password",
  confirmNewPassword: "Confirm new password",
  goToLoginPage: "Go to Login page",
  passwordInstructionHaveSent: "Thanks! Password reset instructions have been sent to {0}.",
  passwordHasBeenSuccessfullyChanged:
    "The password for username {0} has been successfully changed.",
  passwordChanged: "Password changed!",
  sorryWeDontRecognizeThisAccount: "Sorry, we don’t recognize this account",
  passwordRecoveryLinkInvalid:
    "This password recovery link has already been used or is no longer valid.",
  thisEmailHasNotYetBeenActivated: "This email has not yet been activated.",
  thisFleetAccountIsNoLongerActive: "This Fleet Account is no longer active",
  fleetNoLongerActive: "This Fleet Account is no longer active",
  enterYourPasswordAgain: "Enter your email address to reset your password again",
  passwordTokenAlreadyUsed:
    "This password recovery link has already been used or is no longer valid.",
  introductoryPromo: "Introductory Promo",
  withholdingTaxRefund: "Withholding Tax Refund",
  changeRefS: "Change RefS",
  introductoryPromoRebate: "Introductory Promo Rebate",
  reportGenerated: "Report is being generated",
  // reportMessage:
  //   "Export file will be sent to {0} once it is completely generated. If you did not receive any email, kindly message us at {1}.",
  aubAsiaUnitedBank: "AUB (Asia United Bank)",
  bancoDeOro: "BDO (Banco De Oro)",
  bankOfCommerce: "Bank of Commerce",
  bpiBankOfPhil: "BPI (Bank of the Philippine Islands)",
  chinabank: "Chinabank",
  eastwestBank: "EastWest Bank",
  landBank: "Landbank",
  mayBank: "Maybank",
  unionBank: "Unionbank",
  pnbPhilNatBank: "PNB (Philippine National Bank)",
  psBank: "PSBank",
  rcbcRizalCommercialBank: "RCBC (Rizal Commercial Banking Corp)",
  robinsonsBank: "Robinsons Bank",
  ucpb: "UCPB (United Coconut Planters Bank)",
  securityBank: "Security Bank",
  bdoNetworkBank: "BDO Network Bank",
  selectAnOverTheCounterBank: "Step 1: Select an Over-The-Counter Bank",
  theDeadlineForOverTheCounter:
    "The deadline for over-the-counter payments will be stated in the payment instruction email.",
  paymentInstructionsSent: "Payment instructions sent",
  pleaseFollowThePayment:
    "Please carefully read and follow the payment instructions sent to {0} to complete this transaction. Note that the transaction needs to be paid within {1}, and the processing time will vary per bank.",
  onlineBank: "Online Bank",
  downloadSingle: "Download",
  download: "Downloads",
  maximumAmountSelectedBank:
    "Maximum cash-in amount for your selected bank is ₱{0}, but this is still subject to your bank app or website’s transaction limits.",
  cancelled: "Cancelled",
  reportDeleted: "Report Successfully Deleted",
  reportCancelled: "Report Successfully Cancelled",
  reportType: "Report Type",
  deleteReport: "Delete report?",
  deleteReportQuestion: "Are you sure you want to delete {0}? You can't undo this action.",
  dateOfGeneration: "Date of Generation",
  payWithBPI: "Pay with BPI",
  youWillPay: "You will pay {0}.",
  cashInWithBPI: "Cash In with BPI",
  import: "Import",
  importFailed: "Import Failed! (with Errors!) ",
  importDriversByCSV: "Import Drivers by CSV",
  followTheFollowingColumnOrderAndLeaveRow1:
    "Follow the following column order and leave row 1 as column headers to allow system to properly read your file:",
  downloadASampleTemplate: "Download a {0} to see an example of the format required.",
  columnNTitle: "Column {0}: {1}",
  driverFirstName: "Driver First Name",
  driverLastName: "Driver Last Name",
  driverContactNumber: "Driver Contact Number",
  sampleTemplate: "sample-template",
  dragAndDropFilesToUploadOr: "Drag and drop files to upload or",
  addFile: "Add File",
  replaceFile: "Replace File",
  wrongMobileNumber: "Wrong mobile number format (row):",
  fileUploadSuccess: "File uploaded success, but some of your entries were not applied.",
  youMayGoToDriversPage:
    "You may go to Drivers page to check the updated list of driver information",
  importSuccessful: "Import successful!",
  retry: "Retry",
  goToListOfDrivers: "Go to List of Drivers",
  goToListOfVehicles: "Go to List of Vehicles",
  importVehicleByCsv: "Import vehicles by CSV",
  vehiclePlateNumber: "Vehicle Plate Number",
  vehicleType: "Vehicle Type",
  invalidFile: "Invalid File",
  youMayGoToVehiclesPage:
    "You may go to Vehicles page to check the updated list of vehicle information",
  wrongPlateNumberFormat: "Wrong Plate Number format (row):",
  accessAuthorization: "Access Authorization",
  userAccess: "User Access",
  monitorUserAccessToFleetPortal: "Monitor user access to Fleet Portal",
  addUser: "Add User",
  payNow: "Pay Now",
  redemptionStationPumpPrice: "Redemption Station Pump Price",
  weCouldNotFindResults: "We could not find a match for {0}.",
  search: "Search",
  learnMore: "Learn More",
  maybeLater: "Maybe Later",
  noItemSelectedPleaseSelectAnItem: "No item selected. Please select an item in the list.",
  weCouldNotFindAMatchForYou:
    "We could not find a match for your search criteria. Please try a different one.",
  role: "Role",
  admin: "Admin",
  purchaser: "Purchaser",
  fleetManager: "Fleet Manager",
  dataAnalyst: "Data Analyst",
  anAccountActivationInstructionHasBeenSent:
    "An account activation instruction has been sent to the email address you've provided.",
  goToListOfUsers: "Go to List of Users",
  addAnotherUser: "Add Another User",
  saveDetails: "Save Details",
  saveDetailsAndSendEmail: "Save details and send email?",
  saveAndSendEmail: "Save and Send Email",
  uponClickingSaveAndSendEmail:
    'Upon clicking "Save and send email", an account activation instruction will be sent to {0} and the user will have the {1} access. Do you want to proceed?',
  accessType: "Access Type",
  yesChangeRole: "Yes, Change the Role",
  thisWillChangeAccessUser: "This will change the access of {0} . Do you want to proceed?",
  youAreAboutChangeRoleUser: "You are providing the {1} access to {0}",
  saveChanges: "Save Changes",
  saveChangesQuestion: "Save changes?",
  youAreAboutRemoveUser: "{0} will lose access to Fleet Portal. Do you want to proceed?",
  yesRemoveUser: "Yes, Remove",
  exclamatedSuccess: "Success!",
  successfullyRemoveUser: "{0} have been successfully removed.",
  successfullyUpdateUser: "{0} Details have been successfully updated.",
  detailsSuccessfullyUpdated: "Details have been successfully updated.",
  removedUserQuestion: "Remove user?",
  emailAddressExist: "Email address already exist",
  driversName: "Driver's Name",
  activeFuelCodeTotalLiters: "Active Fuel Code Total Liters",
  howsYourExperience: "How’s your experience with PriceLOCQ for Business?",
  noRatingYet: "No Rating Yet",
  skip: "Skip",
  whatMadePriceLOCQGreat: "What made your PriceLOCQ for Business experience great?",
  whatMadePriceLOCQUnsatisfactory:
    "What made your PriceLOCQ for Business experience unsatisfactory?",
  howLikelyAreYouToRefer: "How likely are you to refer PriceLOCQ for Business to friend?",
  unsatisfactory: "Unsatisfactory",
  canBeBetter: "Can be better",
  average: "Average",
  veryGood: "Very Good",
  amazing: "Amazing",
  savingFromFuelHedging: "Saving from fuel hedging",
  pricing: "Pricing",
  fuelCodesAreConvenient: "Fuel codes are convenient",
  fleetPortalWasEasyToUser: "Fleet Portal was easy to use",
  fastAndCashlessTransactions: "Fast and cashless transactions",
  realTimeTransactionsMonitoring: "Real-time transactions monitoring",
  otherReasons: "Other reasons",
  poorCrewAssistanceAtStation: "Poor crew assistance at station",
  portalWasDifficultToUse: "Portal was difficult to use",
  problemsWithFuelCodes: "Problems with fuel codes",
  pricingOrNoValueForMoney: "Pricing / No value for money",
  transactionsWereDifficultToTrack: "Transactions were difficult to track",
  extremelyLike: "Extremely likely",
  veryLikely: "Very likely",
  someWhatLikely: "Somewhat likely",
  notSoLikely: "Not so Likely",
  notAtAllLikely: "Not at all likely",
  submit: "Submit",
  tellUsMorePositiveExperience:
    "Tell us more about your positive experience with PriceLOCQ for Business.",
  tellUsMoreToImprove: "Tell us more about to improve our PriceLOCQ for Business.",
  thankYouForYourFeedBack: "Thank you for your feedback!",
  thankYouDescription: "Your response will greatly help us further improve our services.",
  linkVIPStep1: "Make sure you have created a VIP Biz Account already.",
  linkVIPStep2: "Input the Registered Mobile Number of your VIP Biz Account below.",
  linkAccount: "Link Account",
  linkAccountQuestionMark: "Link Account?",
  linkAccountContent: "Are you sure you would like to link this VIP Biz account to Fleet Portal?",
  linkAccountButton: "Link VIP Biz Account",
  successfullyLinkVipBizAccount: "You have successfully linked your VIP Biz Account.",
  vipAccountDoesntExist: "This VIP Biz Account does not exist",
  vipAccountAlreadyLinked: "This VIP Biz Account is already connected to a PLB Account",
  pointsEarned: "Points Earned",
  poNumber: "Purchase Order No.",
  groups: "Groups",
  manageGroups: "Manage Groups",
  manageYourGroups: "Manage your groups keep their information up to date",
  addGroup: "Add Group",
  enterGroupName: "Enter Group Name",
  groupName: "Group Name",
  groupSuccessRegistered: "Group Successfully Registered!",
  groupSuccessEdit: "Changes Successfully Saved",
  successfullyRegisteredGroupName: "You have successfully added Client {0} to the groups list.",
  successfullyChangeInfoOfGroupName: "You have successfully changed this Group’s details.",
  registerNewGroup: "Register New Group",
  seaoilReciprocity: "Seaoil Reciprocity",
  pumpPrice: "Pump Price",
  id: "ID",
  fleetCardRedemptionDate: "Fleet Card No./ Redemption Date",
  driverIdPlateNumberFleetCardNoOrNumberBusinessName:
    "Driver ID, Plate No., Fleet Card No., OR Number, Station Business Name",
  cardDriverVehicle: "Card No., Assigned Driver, Assigned Vehicle",
  fleetCardRedemptionsHistory: "Fleet Card Redemption History",
  viewTrackRedemptionsFleetCard: "View, track redemption activities of your fleet",
  locqPayBalance: "LOCQPay Balance",
  redeemNow: "Redeem Now",
  cardType: "Card Type",
  locqpayLimit: "LOCQPay Limit",
  locqpayPayment: "LOCQPay Payment this Month",
  transactionLimit: "Transaction Limit",
  transactionThisMonth: "Transaction this Month",
  noLimit: "No Limit",
  creditAmount: "Credit Amount",
  minimumLimit: "Minimum limit is {0}",
  fleetCardQuestion: "fleet card?",
  maximumLimit: "Maximum limit is {0}",
  finalAmountPaid: "Final Amount Paid",
  seaoilAccountManager: "Seaoil Account Manager",
  cashInProcessInstruction: "Cash In Process Instruction",
  makeYourManualDeposit: "1.) Make your manual deposit to the following bank details:",
  accountName: "Account Name",
  locqInc: "LOCQ, INC",
  bankNameAcctNumber: "Bank Name and Account Number",
  bdo_short: "BDO",
  ubp_short: "UBP",
  bpi_short: "BPI",
  bdoAcctNum: "0013 4039 1927",
  ubpAcctNum: "0005 9008 6224",
  bpiAcctNum: "982 100 6462",
  sendUsProof:
    "2.) Send us proof (picture) of your successful payment at {0} together with your Business Name.",
  kindlyWaitForTheStatus:
    "3.) Kindly wait for the status of your request or a staff will contact you.",
  onceCleared: "4.) Once cleared, you will be notified about your cash in via email.",
  pleaseNote50deduction:
    "Please note that we will deduct {0} to your cash in amount for transaction fee.",
  products: "Products",
  locqpayId: "LOCQPay ID",
  fleetCardNo: "Fleet Card No.",
  transactionDate: "Transaction Date",
  orNo: "OR No.",
  nonFuelTransactions: "Non-Fuel Transactions",
  nonFuelTransactionSearchKeys: "Driver ID, Plate Number, Fleet Card No., OR Number, LOCQPay ID",
  monitorNonFuelTransactionsToFleetPortal: "View and track non-fuel transactions of your fleet",
  lubes: "lubes",
  lubricants: "Lubricants",
  transactionRemarks: "Transaction Remarks",
  vipBizPoints: "VIP Points",
  enterVipBizPoints: "Enter VIP Points",
  availableVipBizPoints: "Available VIP Points",
  equivalentLOCQPayAmount: "Equivalent LOCQPay Amount:",
  minimumCashInVIPPoints: "Please enter points not less than 1000.",
  maximumCashInVIPPoints: "Please enter points not greater than the available points.",
  cashInVIPPointsExceed: "Redemption limit exceeded.",
  vipPointsRedemption: "VIP Points Redemption",
  vipPointsDescription:
    "Your VIP Points conversion request is now being processed. Please screenshot this image showing the Reference ID and forward it to your SEAOIL Account Manager.",
  vipPointRedemptionTitle: "VIP Points Conversion Processing",
  pointsRedeemed: "Points Redeemed",
  limitOneTimeExceed200L:
    "The Volume Redeem Limit exceeds 200L. This fuel code will be set to multi-use",
  twoFactorAuth: "Allow two-factor Authenticator?",
  areYouSureYouWantToEnableTwoFactorAuth:
    "Are you sure you want to activate Two-Factor Authentication for {0}?",
  youHaveSuccessfullyActivatedTwoFactorAuth:
    "You have successfully activated Two-Factor Authentication for {0}.",
  activateTwoFactorAuth: "Activate Two-Factor Authentication?",
  yesActivate: "Yes, Activate",
  verifyThatItsYou: "Verify that it's you",
  enterTheCodeThatWasSentToYourEmail: "Enter the code that was sent to your email: {0}",
  verify: "Verify",
  otpInvalidCode: "Verification code invalid. Please check your email and try again.",
  resendCodeIn: "Resend code in {0}",
  expiredOTP: "This code has expired. Please resend code and try again",
  enteredInvalidOTPThreeTimes:
    "You have entered the code incorrectly 3 times. Please try again after one hour.",
  reachedResendLimit:
    "You have reached the limit to receive the code. Please try again after one hour.",
  turnOffTwoFactorAuth: "Turn off Two-Factor Authentication?",
  areYouSureYouWantToTurnOffTwoFactorAuth:
    "Are you sure you want to turn off Two-Factor Authentication for {0}?",
  successTurnedOffTwoFactorAuth:
    "You have successfully turned off Two-Factor Authentication for {0}.",
  yesTurnOff: "Yes, Turn Off",
  resend: "Resend Code",
  lockedAccount:
    "Your Account is temporarily locked out due to failed two-factor authentication. Please try again in a while.",
};

export default Object.freeze(locale);
