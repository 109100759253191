import Validation from "utils/validation.utils";
import locale from "localization";
import { formatAmount } from "utils";

export const initialState = (state = {}, { maximumPoints = 0 }) => {
  const {
    points = {
      value: "",
      name: "points",
      placeholder: "0",
      validations: [
        Validation.required(),
        Validation.min(1000, {
          customMessage: locale.populate(locale.minimumCashInVIPPoints, [formatAmount(1000)]),
          isFormatted: true,
        }),
        Validation.max(maximumPoints, {
          customMessage: locale.maximumCashInVIPPoints,
          isFormatted: true,
        }),
      ],
      required: true,
    },
  } = state;
  return {
    points,
  };
};
